import React from "react";
import config from "../../config";
import { IntercomProvider } from "react-use-intercom";
import { userHasConsentedToPreferences } from "../../utils/cookie-permissions";

const Provider: React.FC = (props) => {
  const { intercomAppId } = config;

  if (intercomAppId != null && userHasConsentedToPreferences()) {
    return (
      <IntercomProvider appId={intercomAppId}>
        {props.children}
      </IntercomProvider>
    );
  } else {
    return <>{props.children}</>;
  }
};

export default Provider;
