import React from "react";
import Link from "next/link";
import {
  useIsAdminContent,
  useLoggedIn,
  useLogout,
} from "../../utils/authentication";
import {
  AccountSuspended,
  AuthPage,
  Intercom,
  LoginForm,
  Navbar,
  VerifyEmail,
} from "../";
import { useLockedPageQuery } from "../../utils/graphql-queries";
import { hasGraphQLError } from "../../utils/graphql-errors";
import { Button } from "@hundred5/design-system";
import { Loader, PageTitle } from "../common";
import config from "../../config";
import { userHasConsentedToPreferences } from "../../utils/cookie-permissions";

interface Props {
  requireVerifiedEmail?: boolean;
}

const LockedPage: React.FC<Props> = (props) => {
  const loggedIn = useLoggedIn();

  if (loggedIn || config.sharedAuthEnabled) {
    return <AuthenticatedContent {...props} />;
  } else if (loggedIn === false) {
    return <UnauthenticatedContent {...props} />;
  } else {
    return <Loader fullScreen />;
  }
};

export default LockedPage;

const AuthenticatedContent: React.FC<{
  requireVerifiedEmail?: boolean;
}> = (props) => {
  const logOut = useLogout();
  const isAdminContent = useIsAdminContent();
  const [{ data, fetching, error }] = useLockedPageQuery();

  React.useEffect(() => {
    if (error != null && hasGraphQLError(error, "Forbidden")) {
      !config.sharedAuthEnabled && logOut();
    }
  }, [error, logOut]);

  if (fetching) {
    return <Loader fullScreen />;
  }
  // Account suspended error is the only Bad Request error we can
  // expect on this page.
  if (error?.networkError?.message === "Bad Request") {
    return <AccountSuspended />;
  }

  const me = data?.me;

  if (me == null) {
    return null;
  }

  if (me.role !== "ADMIN" && isAdminContent) {
    return <ForbiddenContent />;
  }

  const { requireVerifiedEmail = true } = props;

  if (requireVerifiedEmail && !me.isEmailVerified) {
    return <VerifyEmail email={me.email} />;
  }

  return (
    <>
      <Navbar isAdminContent={isAdminContent} />

      {me.isEmailVerified &&
      me.role === "EXPERT" &&
      userHasConsentedToPreferences() ? (
        <Intercom
          userId={me.id}
          name={me.name}
          email={me.email}
          userHash={me.intercomUserHash ?? undefined}
        />
      ) : null}

      {props.children}
    </>
  );
};

const UnauthenticatedContent: React.FC = () => {
  return (
    <>
      <PageTitle title="Log in" />
      <AuthPage
        content={<LoginForm />}
        footer={
          <>
            New to Toggl Expert? <Link href="/signup">Create an account ›</Link>
          </>
        }
      />
    </>
  );
};

const ForbiddenContent: React.FC = () => {
  const logout = useLogout();

  return (
    <>
      <PageTitle title="Forbidden" />
      <AuthPage
        content={
          <>
            <h1>Forbidden</h1>
            <p>Sorry, you cannot access this page. Try logging out.</p>
            <p>
              <Button onClick={() => logout()}>Log out</Button>
            </p>
          </>
        }
      />
    </>
  );
};
